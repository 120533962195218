<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>Terms & Conditions</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="vacancies_area">
            <div class="container">
                <div style="word-wrap:break-word privacy-policy-container">
                    <div class="WordSection1">
                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:12.0pt;font-family:Roboto;color:#002F34"
                                    >POLICY DOCUMENT</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >THESE TERMS OF USE WERE UPDATED ON DECEMBER 19TH 2021</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >PLEASE CAREFULLY READ THESE TERMS OF USE. BY USING THIS PLATFORM,
                                YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IN THE EVENT OF NON-COMPLIANCE WITH THIS TERMS OF USE, SIPL HAS THE
                                RIGHT TO LIMIT OR TERMINATE YOUR ACCESS OR USAGE RIGHTS TO THE
                                PLATFORM IMMEDIATELY OR REMOVE NON-COMPLIANT INFORMATION OR BOTH AS
                                THE CASE MAY BE ,IN ACCORDANCE WITH THE INFORMATION TECHNOLOGY
                                (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA ETHICS CODE) RULES, 2021.
                                BY USING THE SIPL PLATFORM AND ACCESSING OUR SERVICES, YOU INDICATE
                                YOUR UNDERSTANDING AND AGREEMENT TO BE BOUND BY THE TERMS STATED
                                UNDER&nbsp;<u>TERMS OF USE</u>.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THE PLATFORM AND
                                THE SERVICES.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r1"></a>&nbsp;I<b>. ACCEPTANCE</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >SIPL (Refer as, Scholenberg International Private Limited) is an
                                Indian private limited company that is registered at the ROC under
                                ministry of corporate affairs of India under number
                                U74999MH2020PTC344879 and located at D-2/304, Hyde Park, Vasant
                                Vihar, Ghodbunder Road, Thane-400610, India, provides a collection
                                of online resources which include classified advertisements
                                (collectively, the &quot;<b>Service</b>&quot;) on the
                                website&nbsp;</span
                            ><span lang="EN-IN" style="color:black"
                                ><a href="http://www.mepcindia.com"
                                    ><span style="font-size:11.5pt;font-family:Roboto"
                                        >www.mepcindia.com</span
                                    ></a
                                ></span
                            ><span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >&nbsp;and corresponding and the proposed mobile application of the
                                platform (collectively, the &quot;<b>Platform</b>&quot;).</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >The Platform and the Service are provided to you subject to these
                                SIPL Terms of Use (these &quot;<b>Terms</b>&quot;). For the purpose
                                of the Terms and wherever the context so requires, the terms
                                &quot;you&quot; and &quot;“your&quot; shall mean any person who uses
                                or accesses, whether through manual or automated means, the Platform
                                or the Service in any manner whatsoever including persons browsing
                                the Platform and its content, posting comments or any content or
                                responding to any advertisements or content on the Platform. By
                                using the Service, you agree to comply with these Terms.
                                Additionally, when using a portion of the Service, you agree to
                                conform to any applicable posted guidelines for such Service, which
                                may change or be updated from time to time at SIPL’s sole
                                discretion. You understand and agree that you are solely responsible
                                for reviewing these Terms from time to time. Should you object to
                                any term or condition of these Terms, any guideline, or any
                                subsequent changes thereto or become unhappy with SIPL or the
                                Service in any way, your only choice is to immediately discontinue
                                the use of SIPL. These Terms may be updated by SIPL at any time at
                                its sole discretion. SIPL will not provide a translation of the
                                English version of the Terms into other languages.
                            </span>
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r2"></a>&nbsp;II.<b
                                    >&nbsp;DESCRIPTION OF SERVICE AND CONTENT POLICY</b
                                >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >I. SIPL is the next generation of online classifieds for MEPC
                                Sector (EPC &amp; MEP Sector, where EPC stands for Engineering,
                                Procurement, <b>Construction Sector</b> and MEP stands for
                                Mechanical, Electrical and Plumbing Sector). We act as a venue to
                                allow our users who comply with these Terms to offer, post
                                classified for sell, and buy products, Service for material
                                inspectors and other services listed on the Platform along with
                                facility to list their services and source the candidates for their
                                company. Although you may be able to conduct payment and other
                                transactions through or outside the Platform, using any third-party
                                vendors (if any) however SIPL is not in any way involved in such
                                transactions. As a result, and as discussed in more detail in these
                                Terms, you hereby acknowledge and agree that SIPL is not a party to
                                such transactions, has no control over any element of such
                                transactions, and shall have no liability to any party in connection
                                with such transactions. You use the Service and the Platform at your
                                own risk.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >II. You understand that SIPL does not control, and is not
                                responsible for ads, directory information, business
                                listings/information, messages between users, including without
                                limitation e-mails sent from outside SIPL’s domain or other means of
                                electronic communication, whether through the Platform or another
                                Third Party Website or offerings, comments, user postings, files,
                                images, photos, video, sounds, business listings/information and
                                directory information or any other material made available through
                                the Platform and the Service (&quot;<b>Content</b>&quot;), and that
                                by using the Platform and the Service, you may be exposed to Content
                                that is offensive, indecent, inaccurate, misleading, or otherwise
                                objectionable. You acknowledge and agree that you are responsible
                                for and must evaluate, and bear all risks associated with, the use
                                of any Content, that you may not rely on said Content, and that
                                under no circumstances will SIPL be liable in any way for the
                                Content or for any loss or damage of any kind incurred as a result
                                of the browsing, using or reading any Content listed, emailed or
                                otherwise made available via the Service. You acknowledge and agree
                                that SIPL cannot and does not pre-screen or approve any Content, but
                                that SIPL has the right, in its sole and absolute discretion, to
                                refuse, delete or move any Content that is or may be available
                                through the Service, for violating these Terms and such violation
                                being brought to SIPL’s knowledge or for any other reason or no
                                reason at all. Furthermore, the Platform and Content available
                                through the Platform may contain links to other third-party websites
                                (&quot;<b>Third Party Websites</b>&quot;), which are completely
                                unrelated to SIPL. If you link to Third Party Websites, you may be
                                subject to those Third-Party Websites’ terms and conditions and
                                other policies. SIPL makes no representation or guarantee as to the
                                accuracy or authenticity of the information contained in any such
                                Third-Party Website, and your linking to any other websites is
                                completely at your own risk and SIPL disclaims all liability
                                thereto.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >III. You acknowledge and agree that you are solely responsible for
                                your own Content posted on, transmitted through, or linked from the
                                Service and the consequences of posting, transmitting, linking, or
                                publishing it. More specifically, you are solely responsible for all
                                Content that you upload, email, or otherwise make available via the
                                Service. In connection with such Content posted on, transmitted
                                through, or linked from the Service by you, you affirm, acknowledge,
                                represent, warrant and covenant that: (i) you own or have and shall
                                continue to, for such time the Content is available on the Platform,
                                have the necessary licenses, rights, consents, and permissions to
                                use such Content on the Service and Platform (including without
                                limitation all patent, trademark, trade secret, copyright or other
                                proprietary rights in and to any and all such Content) and authorize
                                SIPL to use such Content to enable inclusion and use of the Content
                                in the manner contemplated by the Service, the Platform and these
                                Terms; and (ii) you have the written consent, release, and/or
                                permission of each and every identifiable individual person or
                                business in the Content to use the name or likeness of each and
                                every such identifiable individual person or business to enable
                                inclusion and use of the Content in the manner contemplated by the
                                Service, the Platform and these Terms. For clarity, you retain all
                                of your ownership rights in your Content; however, by submitting any
                                Content on the Platform, you hereby grant to SIPL an irrevocable,
                                non-cancellable, perpetual, worldwide, non-exclusive, royalty-free,
                                sub-licensable, transferable license to use, reproduce, distribute,
                                prepare derivative works of, display, and perform the Content in
                                connection with the Platform and SIPL's (and its successor's)
                                business, including without limitation for the purpose of promoting
                                and redistributing part or all of the Platform and Content therein
                                (and derivative works thereof) in any media formats and through any
                                media channels now or hereafter known. These rights are required by
                                SIPL in order to host and display your Content. Furthermore, by you
                                posting Content to any public area of the Service, you agree to and
                                do hereby grant to SIPL all rights necessary to prohibit or allow
                                any subsequent aggregation, display, copying, duplication,
                                reproduction, or exploitation of the Content on the Service or
                                Platform by any party for any purpose. As a part of the Service,
                                SIPL may offer the facility of automatically capturing the
                                &quot;Description&quot; of your ad based on the images uploaded by
                                you. SIPL makes no warranty on the veracity or the accuracy of the
                                generated Description. The Description may be edited by you at any
                                time while your ad is live. You also hereby grant each user of the
                                Platform (a) a non-exclusive license to access your Content through
                                the Platform, and (b) the right to contact you with regard to the
                                Content posted by you through private chat or any other means. The
                                foregoing license to each user granted by you terminates once you or
                                SIPL remove or delete such Content from the Platform. Further, you
                                grant SIPL the right to make available your Content to any third
                                party in connection with the transaction contemplated in your
                                classified advertisement.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IV. SIPL does not endorse any Content or any opinion, statement,
                                recommendation, or advice expressed therein, and SIPL expressly
                                disclaims any and all liability in connection with User Content.
                                SIPL does not permit copyright infringing activities and
                                infringement of intellectual property rights on the Platform, and
                                SIPL may, at its sole discretion, remove any infringing Content if
                                properly notified in accordance with applicable law that such
                                Content infringes on another's intellectual property rights. SIPL
                                reserves the right to remove any Content without prior notice. SIPL
                                may also terminate a user's access to the Platform if they are
                                determined to be a repeat infringer or found to be indulging in any
                                act contrary to these Terms. A repeat infringer is a user who has
                                been notified of infringing activity more than twice and/or has had
                                a user submission removed from the Platform more than twice.
                                Further, at its sole discretion, SIPL reserves the right to decide
                                whether any Content is appropriate and complies with these
                                Terms.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r3"></a>&nbsp;III.<b>&nbsp;CONDUCT</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >You agree not to post</span
                                ></b
                            ><span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:
Roboto;color:#002F34"
                                >, email, host, display, upload, modify, publish, transmit, store,
                                update or share any information on the Site, or otherwise make
                                available Content:&nbsp;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >I. That violates any law or regulation;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >II. That is copyrighted or patented, protected by trade secret or
                                trademark, or otherwise subject to third party proprietary rights,
                                including privacy and publicity rights unless you are the owner of
                                such rights or have permission or a license from their rightful
                                owner to post the material and to grant SIPL all of the license
                                rights granted herein;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >III. That infringes any of the foregoing intellectual property
                                rights of any party, or is Content that you do not have a right to
                                make available under any law, regulation, contractual or fiduciary
                                relationship(s);</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IV. That is harmful, abusive, unlawful, threatening, harassing,
                                blasphemous, defamatory, obscene, pornographic, paedophilic,
                                libelous, invasive of another's privacy or other rights, including
                                bodily privacy, insulting or harassing on the basis of gender,
                                hateful, or racially, ethnically objectionable, disparaging,
                                relating or encouraging money laundering or gambling or harms or
                                could harm minors in any way or otherwise unlawful in any manner
                                whatsoever or otherwise inconsistent with or contrary to the laws in
                                force;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >V. That harasses degrades intimidates, or is hateful towards any
                                individual or group of individuals based on religion, gender, sexual
                                orientation, race, ethnicity, age, or disability;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >VI. That violates any (local) equal employment laws, including but
                                not limited to those prohibiting the stating, in any advertisement
                                for employment, a preference or requirement based on race, color,
                                religion, sex, national origin, age, or disability of the
                                applicant.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >VII That threatens the unity, integrity, defence, security or
                                sovereignty of India, friendly relations with foreign states, or
                                public order or causes incitement to the commission of any
                                cognizable offense or prevents investigation of any offense or is
                                insulting any other nation;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >VIII. That includes personal or identifying information about
                                another person without that person's explicit consent;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IX. That impersonates any person or entity, including, but not
                                limited to, an SIPL employee, or falsely states or otherwise
                                misrepresents an affiliation with a person or entity;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >X. Deceives or misleads the addressee about the origin of such
                                messages or communicates any information which is grossly offensive
                                or menacing in nature;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XI. That is false, deceptive, misleading, deceitful,
                                misinformative, or constitutes &quot;bait and switch&quot;
                                offer;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XII. That constitutes or contains &quot;pyramid schemes,&quot;
                                &quot;affiliate marketing,&quot; &quot;link referral code,&quot;
                                &quot;junk mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
                                or unsolicited advertisements of a commercial nature;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XIII. That constitutes or contains any form of advertising or
                                solicitation if (1) posted in areas or categories of the Platform
                                which are not designated for such purposes; or (2) e-mailed to SIPL
                                users who have requested not to be contacted about other services,
                                products, or commercial interests;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XIV. That includes links to commercial services or Third-Party
                                Websites, except as specifically allowed by SIPL;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XV. That advertises any illegal services or the sale of any items
                                the sale of which is prohibited or restricted by applicable law,
                                including without limitation items the sale of which is prohibited
                                or regulated by applicable law;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XVI. That contains software viruses, or any other computer code,
                                files or programs designed to interrupt, destroy, or limit the
                                functionality of any computer software or hardware or
                                telecommunications equipment or any other computer resource;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XVII That disrupts the normal flow of dialogue with an excessive
                                number of messages (flooding attack) to the Service, or that
                                otherwise negatively affects other users' ability to use the
                                Service; or</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XVIII. That employs misleading email addresses, or forged headers,
                                or otherwise manipulated identifiers in order to disguise the origin
                                of Content transmitted through the Service.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XIX. That belongs to another person and to which you do not have
                                any right;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XX. That is harmful to child;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XXI. That deceives or misleads the addressee about the origin of
                                the message or knowingly and intentionally communicates any
                                information which is patently false or misleading in nature but may
                                reasonably be perceived as a fact;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >XXII. That is patently false and untrue, and is written or
                                published in any form, with the intent to mislead or harass a
                                person, entity, or agency for financial gain or to cause any injury
                                to any person.&nbsp;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >&nbsp;<b><u>Additionally, you agree not to</u></b
                                >:</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >I. Contact anyone who has asked not to be contacted, or makes
                                unsolicited contact with anyone for any commercial purpose,
                                specifically, contact any user to post an advertisement on a
                                third-party website or post any advertisement on behalf of such
                                user; or to &quot;stalk&quot; or otherwise harass anyone;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >II. Make any libellous or defamatory comments or postings to or
                                against anyone;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >III. Collect personal data about other users or entities for
                                commercial or unlawful purposes;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IV. Use automated means, including spiders, robots, crawlers, data
                                mining tools, or the like to download or scrape data from the
                                Service, except for internet search engines (e.g., Google) and
                                non-commercial public archives (e.g., archive.org) that comply with
                                our robots.txt file;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >V. Post Content that is outside the local area or not relevant to
                                the local area, repeatedly post the same or similar Content, or
                                otherwise impose unreasonable or disproportionately large loads on
                                our servers and other infrastructure;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >VI. Post the same item or service in multiple classified categories
                                or forums, or in multiple metropolitan areas;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >VII. Attempt to gain unauthorized access to computer systems owned
                                or controlled by SIPL or engage in any activity that disrupts,
                                diminishes the quality of, interferes with the performance of, or
                                impairs the functionality of, the Service or the Platform.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >VIII. Use any form of automated device or computer program
                                (sometimes referred to as &quot;flagging tools&quot;) that enables
                                the use of SIPL's &quot;flagging system&quot; or other community
                                control systems without each flag being manually entered by a human
                                that initiates the flag (an &quot;automated flagging device&quot;),
                                or use any such flagging tool to remove posts of competitors, other
                                third parties or to remove posts without a reasonable good faith
                                belief that the post being flagged violates these Terms or any
                                applicable law or regulation.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IX. Use any automated device or software that enables the
                                submission of automatic postings on SIPL without human intervention
                                or authorship (an &quot;automated posting device&quot;), including
                                without limitation, the use of any such automated posting device in
                                connection with bulk postings, or for automatic submission of
                                postings at certain times or intervals; or</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >X. Any Content uploaded by you shall be subject to relevant laws
                                and may disabled, or and may be subject to investigation under
                                appropriate laws. Furthermore, if you are found to be in
                                non-compliance with the laws and regulations, these terms, or the
                                privacy policy of the Site, we may terminate your account/block your
                                access to the Site and we reserve the right to remove any
                                non-compliant Content uploaded by you.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:18.0pt;font-family:Roboto;color:#002F34"
                                    >Payment for Paid Services</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >Upon selecting any feature of Paid Services, you may be redirected
                                to a third-party payment processing site such as PayU offering
                                payment through various channels such as net banking, debit card and
                                credit card (“<b>Payment</b>&nbsp;<b>Gateway”</b>). SIPL may offer
                                you a right to pay through cash or other channels such as cash
                                collection. You agree that Paid Services will be made available to
                                you only upon receipt of payment confirmation from the Payment
                                Gateway or collection agencies. Upon making payment to us, through
                                any medium whatsoever, you warrant to us that you are entitled to
                                use the medium or otherwise make the payment to us under applicable
                                law.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You have the ability to provide us your Goods and Services Tax
                                Identification Number (GSTIN) on the platform. Your GSTIN details
                                are mandatory for passing on the credit of GST charged. In case you
                                fail to provide us with your GSTIN details, we will assume that you
                                are not registered with the GST authorities, without independently
                                verifying your registration status.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >Unless otherwise mentioned on the Website, prices indicated are
                                inclusive of applicable taxes.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >&nbsp;</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:18.0pt;font-family:Roboto;color:#002F34"
                                    >Refunds/Cancellation</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >If you choose to delete or deactivate any Paid Services prior to
                                the period for which it is active, we will not be liable to refund
                                you for any unutilized time.
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >The refund policy shall not be applicable in the event any content
                                in your advertisement is found to be violating the Terms or there
                                has been any abuse reported in relation to your advertisement or
                                your advertisement is deleted or not re-posted on the Website on
                                account of any technical reasons due to any subsequent editing done
                                by you.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r8"></a>&nbsp;IV.&nbsp;<b
                                    >INTELLECTUAL PROPERTY RIGHTS</b
                                >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You acknowledge and agree that the materials on the Platform, other
                                than the User Content that you licensed under Section ii(C) of the
                                Terms, including without limitation, the text, software, scripts,
                                graphics, photos, sounds, music, videos, interactive features and
                                the like (&quot;<b>Materials</b>&quot;) and the trademarks, service
                                marks and logos contained therein (&quot;<b>Marks</b>&quot;), are
                                owned by or licensed to SIPL, and are subject to copyright and other
                                intellectual property rights under Dutch laws, foreign laws and
                                international treaties and/or conventions. In connection with the
                                Services, the Platform may display certain trademarks belonging to
                                third parties. Use of these trademarks may be subject to the license
                                granted by third parties to SIPL. You shall, in no event, reverse
                                engineer, decompile, or disassemble such trademarks and nothing
                                herein shall be construed to grant you any right in relation to such
                                trademarks. Materials on the Platform are provided to you AS IS for
                                your information and personal use only and may not be used, copied,
                                reproduced, distributed, transmitted, broadcast, displayed, sold,
                                licensed, or otherwise exploited for any other purposes whatsoever
                                without the prior written consent of the respective owners. SIPL
                                reserves all rights not expressly granted herein to the Platform and
                                the Materials. You agree to not engage in the use, copying, or
                                distribution of any of the Materials other than as expressly
                                permitted herein, including any use, copying, or distribution of
                                Materials of third parties obtained through the Platform for any
                                commercial purposes. If you download or print a copy of the
                                Materials for personal use, you must retain all copyright and other
                                proprietary notices contained therein. You agree not to circumvent,
                                disable or otherwise interfere with security-related features of the
                                Platform or features that prevent or restrict the use or copying of
                                any Materials or enforce limitations on the use of the Platform or
                                the Materials therein. The Service is protected to the maximum
                                extent permitted by copyright laws, other laws, and international
                                treaties and/or conventions. The content displayed on or through the
                                Service is protected by copyright as a collective work and/or
                                compilation, pursuant to copyrights laws, other laws, and
                                international conventions. Any reproduction, modification, creation
                                of derivative works from or redistribution of the Platform, the
                                Materials, or the collective work or compilation is expressly
                                prohibited. Copying or reproducing the Platform, the Materials, or
                                any portion thereof to any other server or location for further
                                reproduction or redistribution is expressly prohibited. You further
                                agree not to reproduce, duplicate or copy Content or Materials from
                                the Service, and agree to abide by any and all copyright notices and
                                other notices displayed on the Service. You may not decompile or
                                disassemble, reverse engineer or otherwise attempt to discover any
                                source code contained in the Service. Without limiting the
                                foregoing, you agree not to reproduce, duplicate, copy, sell, resell
                                or exploit for any commercial purposes, any aspect of the Service.
                            </span>
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >V.<b>&nbsp;USER SUBMISSIONES</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You understand that when using the Platform, you will be exposed to
                                Content from a variety of sources and that SIPL is not responsible
                                for the accuracy, usefulness, safety, or intellectual property
                                rights of or relating to such Content, and you agree and assume all
                                liability for your use. You further understand and acknowledge that
                                you may be exposed to Content that is inaccurate, offensive,
                                indecent, or objectionable, defamatory, or libelous and you agree to
                                waive, and hereby do waive, any legal or equitable rights or
                                remedies you have or may have against SIPL with respect
                                thereto.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r10"></a>&nbsp;VI.&nbsp;<b>INDEMNITY</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You agree to defend, indemnify and hold harmless SIPL, its
                                officers, subsidiaries, affiliates, successors, assigns, directors,
                                officers, agents, service providers, suppliers, and employees, from
                                and against any and all claims, damages, obligations, losses,
                                liabilities, costs or debt, and expenses (including but not limited
                                to attorneys' fees) arising from (i) your use of and access to the
                                Platform and/or the Service; (ii) your violation of any term of
                                these Terms; (iii) your violation of any third party right,
                                including without limitation any copyright, trademark, trade secret
                                or other property, or privacy right; or (iv) any claim that your
                                Content caused damage to a third party. This defence and
                                indemnification obligation will survive termination, modification,
                                or expiration of these Terms and your use of the Service and the
                                Platform.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r11"></a>&nbsp;VII.<b>&nbsp;NO SPAM POLICY</b></span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You understand and agree that sending unsolicited email
                                advertisements or other unsolicited communications to SIPL email
                                addresses or through SIPL computer systems is expressly prohibited
                                by these Terms. You acknowledge and agree that from time-to-time
                                SIPL may monitor email usage using human monitors or automated
                                software to flag certain words associated with spam or scams in
                                emails that are sent between one user to another in the SIPL email
                                system. Any communication between yourself and any other user
                                utilizing the communication features available on the Service and
                                the Platform may be used only in accordance with the Terms. Any
                                unauthorized use of SIPL computer systems is a violation of these
                                Terms and certain applicable laws. Such violations may subject the
                                sender and his or her agents to civil and criminal penalties.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r12"></a>&nbsp;VIII.<b
                                    >&nbsp;DEALINGS WITH ORGANIZATIONS AND INDIVIDUALS</b
                                >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You acknowledge and agree that SIPL shall not be liable for your
                                interactions with any organizations and/or individuals on the
                                Platform or through the Service. This includes, but is not limited
                                to, payment and delivery of goods and services, and any other terms,
                                conditions, warranties, or representations associated with any
                                interaction you may have with other organizations and/or
                                individuals. These dealings are solely between you and such
                                organizations and/or individuals. You agree and acknowledge that
                                SIPL shall not be responsible or liable for any loss or damage of
                                any sort incurred as the result of any such dealings or
                                interactions. If there is a dispute between participants on the
                                Platform, or between users and any third party, you understand and
                                agree that SIPL is under no obligation to become involved in such
                                dispute. In the event that you have a dispute with one or more other
                                users, you hereby release SIPL, its officers, employees, agents, and
                                successors from any and all claims, demands, and damages (actual and
                                consequential) of every kind or nature, known or unknown, suspected
                                and unsuspected, disclosed and undisclosed, arising out of or in any
                                way related to such disputes and/or our Service.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r13"></a>&nbsp;IX.<b
                                    >&nbsp;LIMITATION AND TERMINATION OF SERVICE</b
                                >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You acknowledge and agree that SIPL may establish limits from time
                                to time concerning the use of the Service, including among others,
                                the maximum number of days that Content will be maintained or
                                retained by the Service, the maximum number and size of postings,
                                e-mail messages, or other Content that may be transmitted or stored
                                by the Service, and the frequency and the manner in which you may
                                access the Service or the Platform. You acknowledge that your
                                account is identified and linked through your mobile number,
                                Facebook account, or email address through which you have
                                registered. In the event you have more than one account linked
                                through your mobile number, Facebook account, or email address, SIPL
                                reserves the right to remove or restrict the usage of such duplicate
                                accounts. You acknowledge and agree that SIPL has no responsibility
                                or liability for the deletion or failure to store any Content
                                maintained or transmitted by the Platform or the Service. You
                                acknowledge and agree that SIPL reserves the right at any time to
                                modify, limit or discontinue the Service (or any part thereof) with
                                or without notice and that SIPL shall not be liable to you or to any
                                third party for any such modification, suspension or discontinuance
                                of the Service. You acknowledge and agree that SIPL, in its sole and
                                absolute discretion, has the right (but not the obligation) to
                                delete or deactivate your account, block your e-mail or IP address,
                                or otherwise terminate your access to or use of the Service (or any
                                part thereof), immediately and without notice, and remove and
                                discard any Content within the Service, for any reason or no reason
                                at all, including, without limitation, if SIPL believes that you
                                have violated these Terms. Further, you agree that SIPL shall not be
                                liable to you or any third-party for any termination of your access
                                to the Platform or the Service. Further, you agree not to attempt to
                                use the Service after any such termination.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r14"></a>&nbsp;X<b>. DISCLAIMER OF WARRANTIES</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PLATFORM AND
                                THE SERVICE IS ENTIRELY AT YOUR OWN RISK AND THAT THE PLATFORM AND
                                THE SERVICE ARE PROVIDED ON AN &quot;AS IS&quot; OR &quot;AS
                                AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ALL
                                EXPRESS AND IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                                NON-INFRINGEMENT OF PROPRIETARY RIGHTS ARE EXPRESSLY DISCLAIMED TO
                                THE FULLEST EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT PERMITTED
                                BY LAW SIPL, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM
                                ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM
                                AND YOUR USE THEREOF. SIPL MAKES NO WARRANTIES OR REPRESENTATIONS
                                ABOUT THE ACCURACY OR COMPLETENESS OF THE PLATFORM'S CONTENT OR THE
                                CONTENT OF ANY THIRD-PARTY WEBSITES LINKED TO THE PLATFORM AND
                                ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES,
                                OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE,
                                OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
                                THE PLATFORM AND SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF
                                OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                                INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF
                                TRANSMISSION TO OR FROM THE PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN
                                HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
                                PLATFORM BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN
                                ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                                RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, COMMUNICATED,
                                TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM OR THE
                                SERVICE. SIPL DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
                                THIRD PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED WEBSITE OR
                                FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND SIPL WILL NOT BE A
                                PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
                                BETWEEN YOU AND/OR OTHER USERS AND/OR THIRD-PARTY PROVIDERS OF
                                PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                                THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                                JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r15"></a>&nbsp;XI.<b
                                    >&nbsp;LIMITATIONS OF LIABILITY</b
                                >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >IN NO EVENT SHALL SIPL, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR
                                AGENTS, BE LIABLE FOR DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                                CONSEQUENTIAL, OR EXEMPLARY DAMAGES (EVEN IF SIPL HAS BEEN ADVISED
                                OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM ANY ASPECT OF
                                YOUR USE OF THE PLATFORM OR THE SERVICE, INCLUDING WITHOUT
                                LIMITATION WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF THE
                                PLATFORM OR THE SERVICE, FROM INABILITY TO USE THE PLATFORM OR THE
                                SERVICE, OR THE INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION,
                                OR TERMINATION OF THE PLATFORM OR THE SERVICE. SUCH LIMITATION OF
                                LIABILITY SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY
                                REASON OF OTHER SERVICES OR PRODUCTS RECEIVED THROUGH OR ADVERTISED
                                IN CONNECTION WITH THE PLATFORM OR THE SERVICE OR ANY LINKS ON THE
                                PLATFORM, AS WELL AS BY REASON OF ANY INFORMATION, OPINIONS OR
                                ADVICE RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH THE
                                PLATFORM OR THE SERVICE OR ANY LINKS ON THE SIPL SITE. THESE
                                LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. YOU
                                SPECIFICALLY ACKNOWLEDGE AND AGREE THAT SIPL SHALL NOT BE LIABLE FOR
                                USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF
                                ANY USER OR THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE
                                FOREGOING RESTS ENTIRELY WITH YOU.&nbsp;<b
                                    >THE PLATFORM IS CONTROLLED AND OFFERED BY SIPL. SIPL MAKES NO
                                    REPRESENTATIONS OR WARRANTIES THAT THE PLATFORM IS APPROPRIATE
                                    FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE PLATFORM
                                    FROM OTHER JURISDICTIONS DO SO AT THEIR OWN VOLITION AND RISK
                                    AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAW.</b
                                ></span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r16"></a>&nbsp;XII.<b>&nbsp;ASSIGNMENT</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >These Terms, and any rights and licenses granted hereunder, may not
                                be transferred or assigned by you but may be assigned by SIPL
                                without restriction. Any assignment or transfer by you shall be null
                                and void.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r17"></a>&nbsp;XIII<b
                                    >. ABILITY TO ACCEPT TERMS OF SERVICE</b
                                >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >This Platform is intended only for adults and that you are eligible
                                to contract as per applicable laws. If you are using/accessing this
                                Platform as a representative of any person/entity, you acknowledge
                                that you are legally authorized to represent that person/entity.
                                Minors, i.e. users under 18 years of age, are only allowed to access
                                the Platform and use the Service, in the event of approval of their
                                legal representatives or in the event that it concerns an act or a
                                transaction that is a usual and acceptable standard in civil life
                                and practice. You affirm that you are either at least 18 years of
                                age, or an emancipated minor, or possess legal parental or guardian
                                consent and are fully able and competent to enter into the terms,
                                conditions, obligations, affirmations, representations, and
                                warranties set forth in these Terms, and to abide by and comply with
                                these Terms. In any case, you affirm that you are over the age of
                                13, as the Platform is not intended for children under 13.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >NOTICE TO CHILDREN UNDER THE AGE OF 13 AND THEIR PARENTS OR
                                GUARDIANS If you are under the age of 13, YOU MUST NOT USE THIS
                                PLATFORM. Please do not send us your personal information, including
                                your email addresses, name, and/or contact information. If you want
                                to contact us, you may only do so through your parent or legal
                                guardian.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r18"></a>&nbsp;XIV<b>&nbsp;GENERAL INFORMATION</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >These Terms and the other policies posted on the Platform
                                constitute the complete and exclusive understanding and agreement
                                between you and SIPL and govern your use of the Service and the
                                Platform superseding all prior understandings, proposals,
                                agreements, negotiations, and discussions between the parties,
                                whether written or oral. The Terms and the relationship between you
                                and SIPL shall be governed by the laws of the Netherlands. Any claim
                                you may have against SIPL must be submitted to the exclusive
                                jurisdiction of the courts of Amsterdam, The Netherlands. However,
                                in the event that you are a consumer, it may be that consumer law
                                requires that another law is applicable and that a claim may be
                                submitted to another jurisdiction. The failure of SIPL to exercise
                                or enforce any right or provision of the Terms shall not constitute
                                a waiver of such right or provision. If any provision of the Terms
                                is found by a court of competent jurisdiction to be invalid
                                (including, without limitation, because such provision is
                                inconsistent with the laws of another jurisdiction) or inapplicable,
                                the parties nevertheless agree that the court should endeavour to
                                give effect to the parties' intentions as reflected in the
                                provision. If any provision or provisions of these Terms is held to
                                be invalid, illegal, or unenforceable, the validity, legality, and
                                enforceability of the remaining provisions of the Terms shall not in
                                any way be affected or be impaired. YOU AGREE THAT ANY CAUSE OF
                                ACTION BROUGHT BY YOU AND ARISING OUT OF OR RELATED TO YOUR USE OF
                                THE SERVICE AND/OR THE PLATFORM MUST COMMENCE WITHIN A REASONABLE
                                TIME AND IN ANY EVENT WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                                ACCRUES, EXCEPT THAT SIPL MAY COMMENCE ANY SUCH CAUSE OF ACTION IN
                                ACCORDANCE WITH THE APPLICABLE STATUTE OF LIMITATIONS UNDER DUTCH
                                LAW. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED. These
                                Terms shall inure to the benefit of and be binding upon each party's
                                successors and assigns.</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                    >&nbsp; XV. PRIVACY POLICY</span
                                ></b
                            ><span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:
Roboto;color:#00080F"
                            >
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >A. Type of Information Collected:</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >When you visit the Platform, we may collect certain non-personal
                                information such as your Internet Protocol (&quot;IP&quot;) address,
                                operating system, browser type, and Internet service provider. This
                                type of information does not identify you personally. When you
                                register with the Platform, we may also collect personal information
                                that you provide such as your name, mailing address, email address,
                                phone/mobile number, home country, PIN/ZIP code, and certain other
                                information in the event you log in via Facebook depending on your
                                privacy settings including but not limited to your name, email
                                address, profile picture, list of friends, education, etc.
                                (&quot;Registration Information&quot;). You have the option of also
                                providing to us your fax numbers, gender, age, and/or company name
                                if you choose to register with SIPL. If you provide your phone
                                number, it may be displayed in your posting. Further, if you have
                                provided your phone number and posted an advertisement using your
                                account, you agree to receive communications on the provided phone
                                number from our site which may include but not be limited to
                                automated calls or text messages. The communication may require you
                                to confirm and verify that your account has been used to post the
                                relevant advertisement on the Platform. We may also collect
                                additional information that our users provide, such as new or
                                deleted postings, new or deleted comments, keyword searches, scam
                                reports, and new contact sellers. We use a third-party payment
                                gateway for purchases, and other third-party companies to monitor
                                site traffic, which may, in some instances, store your information
                                By using this Platform or the Service, you consent to the
                                collection, storage, and use of the personal information you provide
                                for any of the services that we offer, and you consent to our
                                collection of any changes or updates that you may provide to any
                                information you provide that is collected by SIPL.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >By using this Platform, you consent to share your geolocation data
                                in order to publish information/advertisements of advertisements
                                offered by users close to your location and vice-versa.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >B. Cookies</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >We may use cookies to manage our users’ sessions and to store
                                preferences, tracking information, and language selection. Cookies
                                may be used whether you register with us or not. &quot;Cookies&quot;
                                are small text files transferred by a web server to your hard drive
                                and thereafter stored on your computer. The types of information a
                                cookie collects include the date and time you visited, your browsing
                                history, your preferences, and your username. In some instances, our
                                third-party service providers may use cookies on the Platform. We
                                cannot control or access cookies used by third-party service
                                providers. This Privacy Policy covers only Cookies used by us and
                                not any cookies used by third parties. You have the ability to
                                either accept or decline the use of cookies on your computer,
                                whether you are registered with us or not. Typically, you can
                                configure your browser to not accept cookies. However, declining the
                                use of cookies may limit your access to certain features of the
                                Platform. For example, you may have difficulty logging in or using
                                certain interactive features of the Platform, such as the SIPL Forum
                                or Comments feature.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >C. How Your Information is Used</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >We may use the information provided by you to: (i) enforce our
                                Terms of Use, monitor user activity, such as keyword searches or new
                                postings, and more effectively manage traffic on the Platform; (ii)
                                provide customer services, create and manage user accounts; and
                                (iii) assist you with technical difficulties. Also, we may share
                                with third-party service providers certain information, such as your
                                browser capabilities or operating system, that we have collected in
                                order to better understand which advertisements and services may
                                interest you. We may block users located in certain countries from
                                using the Platform. We may retain such information for as long as is
                                required to fulfill our business objective, even after your account
                                is terminated.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >D. Protecting Your Personal Information</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >You are a valued customer, and we recognize that protecting your
                                privacy is important to you. For this reason, we are committed to
                                protecting the personal information you provide in a variety of
                                ways. We do not directly process any payments and do not store your
                                credit card information. Secured socket layer
                                (&quot;<b>SSL</b>&quot;) technology is used for processing payment
                                transactions with third-party service provider partners, such as
                                PayU, PayPal, Razor pay, etc. Your Registration Information may be
                                protected by a unique customer password and user ID. You should not
                                disclose your password information to anyone and you should always
                                remember to log off if using a shared computer. Lastly, you may
                                utilize the Platform as an anonymous user by not registering. We
                                have taken certain security protections in safeguarding your
                                personal information. However, as with most electronic transactions,
                                no method is 100% safe. While we strive to use a commercially
                                acceptable means to protect the personal information you provide, we
                                cannot guarantee its security. Therefore, you acknowledge and agree
                                that we assume no liability regarding the theft, loss, alteration or
                                misuse of personal or other information or Content, including,
                                without limitation, such information that has been provided to third
                                parties or other users, or with regards to the failure of a third
                                party to abide by the agreement between us and such third party. You
                                may participate in our Forum or utilize our Comments feature. For
                                postings to the Forum, your username will be posted. For postings
                                using the Comments feature, your name and email will only be posted
                                if you provide it in the comment. We strongly discourage posting any
                                information on these features or in any Content posted by you that
                                you do not want others to see. You agree that you are responsible
                                for maintaining the confidentiality of your username and password,
                                and all uses of your account, whether or not you have authorized
                                such use.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >E. Disclosures</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >On rare occasions, we may be required to disclose your personal
                                information due to legal or regulatory requirements. In such
                                instances, we reserve the right to disclose your personal
                                information as required in order to comply with our legal
                                obligations, including but not limited to complying with court
                                orders, warrants, subpoenas, service of process requirements, or
                                discovery requests. We may also disclose information about our users
                                to law enforcement officers or others, in the good faith belief that
                                such disclosure is reasonably necessary to enforce these Terms
                                and/or Privacy Policy; respond to claims that any Content violates
                                the rights of third-parties; or protect the rights, property, or
                                personal safety of SIPL, our users or the general public; or we are
                                in notice of any criminal activity being conducted via the Platform.
                                You agree and acknowledge that we may not inform you prior to or
                                after disclosures made according to this section. If substantially
                                all of our assets are sold or merged into another company, the
                                acquiring company shall be given access to your personal information
                                without your consent. If we sell only a part of our business, the
                                acquiring entity shall have access to your personal information
                                without your consent and we may send you a notice regarding this
                                sale.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >F. Notification of Changes</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >We reserve the right to change these Terms from time to time, and
                                at our sole discretion. We may not send you a notice regarding
                                material changes to these Terms, including the Privacy Policy or the
                                Platform. You should make note of the &quot;last updated date&quot;
                                at the beginning of the Terms. With this information, you should be
                                able to identify if the Terms have been updated recently.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >G. Disputes</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >Any disputes regarding your privacy are subject to the Terms,
                                including but not limited to any provisions related to
                                indemnification, limitations on damages, and choice of law and
                                forum.</span
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <b
                                ><span
                                    lang="EN-IN"
                                    style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                    >H. How to Contact Us</span
                                ></b
                            >
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >If you have questions about this Privacy Policy, please contact
                                SIPL&nbsp;via email id </span
                            ><span lang="EN-IN" style="color:black"
                                ><a href="mailto:info@mepcindia.com"
                                    ><span
                                        style="font-size:11.5pt;font-family:Roboto;color:#002F34;text-decoration:none"
                                        >info@mepcindia.com</span
                                    ></a
                                ></span
                            ><span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                            >
                                or call us at +91 8779440200 on Monday to Friday between (9:30 AM to
                                6:30 PM)</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-bottom:0in;line-height:normal;background:#D7DDF2"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:9.0pt;font-family:Roboto;color:#00080F"
                                >&nbsp;<a name="r21"></a>&nbsp;XVI<b>. GRIEVANCE OFFICER</b>
                            </span>
                        </p>

                        <p class="MsoNormal" style="line-height:normal;background:white">
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >If you have any grievance with respect to the Platform or the
                                Service, you can contact our grievance officer at:</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-left:123.0pt;text-indent:-.25in;line-height:
normal;background:white"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:10.0pt;font-family:
Symbol;color:#002F34"
                                >·<span style='font:7.0pt "Times New Roman"'
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span></span
                            ><span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;
color:#002F34"
                                >Name: MANISHA YADAV</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-left:123.0pt;text-indent:-.25in;line-height:
normal;background:white"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:10.0pt;font-family:
Symbol;color:#002F34"
                                >·<span style='font:7.0pt "Times New Roman"'
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span></span
                            ><span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;
color:#002F34"
                                >Designation: Head-Customer Support and Content Quality</span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-left:123.0pt;text-indent:-.25in;line-height:
normal;background:white"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:10.0pt;font-family:
Symbol;color:#002F34"
                                >·<span style='font:7.0pt "Times New Roman"'
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span></span
                            ><span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;
color:#002F34"
                                >Email:&nbsp;</span
                            ><span lang="EN-IN" style="color:black"
                                ><a href="mailto:info@mepcindia.com"
                                    ><span
                                        style="font-size:11.5pt;font-family:
Roboto"
                                        >info@mepcindia.com</span
                                    ></a
                                ></span
                            >
                        </p>

                        <p
                            class="MsoNormal"
                            style="margin-left:123.0pt;line-height:normal;background:
white"
                        >
                            <span
                                lang="EN-IN"
                                style="font-size:11.5pt;font-family:Roboto;color:#002F34"
                                >&nbsp;</span
                            >
                        </p>

                        <p class="MsoNormal"><span lang="EN-IN">&nbsp;</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../../_helper/http-constants"
import axios from "axios"
import scrollTop from "../utils/scrollTop"

export default {
    name: "About",

    data() {
        return {
            user: "",

            // imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            // imgsrc: "http://localhost/backend/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,
        }
    },

    mounted() {
        scrollTop()
        HTTP.get("/api/job").then((res) => {
            this.user = res.data
        })
    },
}
</script>
<style lang="scss" scoped>
/* Font Definitions */
@font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
    font-family: Calibri;
    panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
    font-family: Roboto;
    panose-1: 0 0 0 0 0 0 0 0 0 0;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}
a:link,
span.MsoHyperlink {
    color: #0563c1;
    text-decoration: underline;
}
.MsoChpDefault {
    font-family: "Calibri", sans-serif;
}
.MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
}
@page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 1in 1in 1in 1in;
}
div.WordSection1 {
    page: WordSection1;
}
/* List Definitions */
ol {
    margin-bottom: 0in;
}
ul {
    margin-bottom: 0in;
}
.privacy-policy-container {
}
</style>
